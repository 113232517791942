// import Spinner from "../Spinner";
import clsx from "clsx";
import React from "react";

const Button = (props) => {
  const style = props.options?.style ?? "default";
  const size = props.options?.size ?? "large";
  const autoHeight = props.options?.autoHeight ?? false;
  const disabled = props.disabled;
  const iconPosition = props.iconPosition ?? "before";
  const loading = props.loading ?? false;

  let outerClassName = clsx(
    "w-button",
    "w-button--" + style,
    "w-button--" + size,
    {
      "tw-opacity-20": disabled,
      "tw-relative": loading,
      "w-button--auto-height": autoHeight,
    },
    props.className
  );

  let innerClassName = "w-button__inner";

  const inner = (
    <>
      {iconPosition === "before" && props.icon}
      <span className={innerClassName}>{props.title}</span>
      {iconPosition === "after" && props.icon}
      {/* {loading && <Spinner className="!tw-absolute tw-right-2 tw-top-2 tw-w-4 tw-h-4" />} */}
      {loading && (
        <div className="!tw-absolute tw-left-2 tw-top-2 tw-w-2 tw-h-2 tw-rounded-full tw-bg-current tw-animate-pulse" />
      )}
    </>
  );

  return (
    <>
      {props.href && (
        <a className={outerClassName} target={props.target} href={props.href}>
          {inner}
        </a>
      )}
      {!props.href && props.onClick && props.type !== "submit" && (
        <button
          disabled={disabled}
          className={outerClassName}
          onClick={props.onClick}
          type={props.type}
        >
          {inner}
        </button>
      )}
      {!props.href && !props.onClick && props.type === "submit" && (
        <button disabled={disabled} className={outerClassName} type={"submit"}>
          {inner}
        </button>
      )}
      {!props.href && !props.onClick && props.type !== "submit" && (
        <div className={outerClassName}>{inner}</div>
      )}
    </>
  );
};

export default Button;
