import { clsx } from "clsx";
import PropTypes from "prop-types";
import React from "react";

const Format = ({ as, preset, children, className, dangerouslySetInnerHTML }) => {
  const Component = as || "div";
  className = clsx("w-o-format-" + preset, className);

  // switch (preset) {
  //     case "button-small":
  //         className +=
  //             "tw-font-medium tw-text-[0.6875em] tw-leading-[1.1em] tw-tracking-widest tw-uppercase";
  //         break;
  //     default:
  //         break;
  // }

  return (
    <Component dangerouslySetInnerHTML={dangerouslySetInnerHTML} className={className}>
      {children}
    </Component>
  );
};

Format.propTypes = {
  preset: PropTypes.oneOf([
    "h1",
    "h1--mobile",
    "h2",
    "h2--mobile",
    "h3",
    "h3--mobile",
    "h4",
    "h4--mobile",
    "h5",
    "h5--mobile",
    "figure",
    "figure--mobile",
    "lead",
    "lead--mobile",
    "body-large",
    "body-large--mobile",
    "body-small",
    "body-small--mobile",
    "overline",
    "overline--mobile",
    "caption",
    "caption--mobile",
    "button",
    "button-small",
    "menu-level-3-title",
    "menu-level-3-item",
  ]),
};
export default Format;
