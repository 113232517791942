/* global document */
import i18n from "i18next";
import Backend from "i18next-http-backend";
import { initReactI18next } from "react-i18next";

const path = "/wp-json/koenig/v1/finder/translations";

i18n
  .use(Backend)
  .use(initReactI18next)
  .init({
    lng: document.documentElement.lang.split("-")[0],
    fallbackLng: "de",
    debug: false,
    backend: {
      loadPath: `${path}/?lang={{lng}}`,
    },
    interpolation: {
      escapeValue: false, // not needed for react as it escapes by default
    },
  });

export default i18n;
